import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccountData } from '../../app/web3Slice';
import { Link } from 'react-router-dom';
import { BlockAmount, dispNum } from '../../common';
import { doBuyApprove, doBuyBuy } from './buySlice';




export default function Buy() {

	const accountData = useSelector(selectAccountData);

	const [buyAmount, setBuyAmount] = useState(0);
	const [buyAmountCalc, setBuyAmountCalc] = useState(null);

	const dispatch = useDispatch();

	useEffect(() => {
		setBuyAmountCalc(buyAmount / 0.01);
	},  [buyAmount]);


	return (
		<div className="page">
			<h1>Buy</h1>
			<div className="page-main">
			<div className="block block-value">
				<span className="title">USDT Balance</span>
				<span className="value">
					<BlockAmount amount={accountData.uBalance} />
					<span className="dim">USDT</span>
				</span>
			</div>			
			<div className="block block-value">
				<span className="title">USDT Approve limit</span>
				<span className="value">
					<BlockAmount amount={accountData.USDTApproveLimit} />
					<span className="dim">USDT</span>
				</span>
			</div>			
			<div className="block">
				<div className="btn-group">
					<input type="number" className="form-control amt" style={{ textAlign: 'right' }} 
						value={buyAmount}  onChange={(e) => setBuyAmount(parseFloat(e.target.value))} />
					<button type="button" 
						className={'btn' + (buyAmount <= accountData.USDTApproveLimit ? ' btn-secondary' : ' btn-primary')}
						onClick={() => dispatch(doBuyApprove(buyAmount))} >Approve</button>
					<button type="button" className='btn btn-primary'
						disabled={buyAmount>accountData.USDTApproveLimit || buyAmount > accountData.uBalance}
						onClick={() => dispatch(doBuyBuy(buyAmount))}>Buy</button>
				</div>
				{buyAmount > accountData.USDTApproveLimit && (<div className='calc text-danger'>
					Approved amount is not enough
				</div>)}
				{buyAmount > accountData.uBalance && (<div className='calc text-danger'>
					Your USDT balace is not enough
				</div>)}
				{!buyAmountCalc && (<div className='calc'>
					Enter the amount!
				</div>)}
				{!!buyAmountCalc && (<div className='calc'>
					= <span className='amt'>{dispNum(buyAmountCalc)}</span> SDBN1
				</div>)}
			</div>
			<div className="block block-value">
				<span className="title">Token price</span>
				<span className="value">
					<BlockAmount amount={1} />
					<span className="dim">cent / Token</span>
				</span>
			</div>
			<div className="block">
				<Link className="btn btn-secondary" to='/'>Home</Link>
			</div>
		</div>
		</div>
	)
};


