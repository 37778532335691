import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BlockAmount, CSVToArray, dispAddress, dispNum } from "../../common";
import { doFetchLastDate, doPayout } from "./payoutSlice";
import { CHAIN, PAYOUT_IGNORE_ACCOUNTS, token_address } from "../../config/config";







export default function Payout() {

	const dispatch = useDispatch();

	const lastDate = useSelector(state => state.web3Payout.lastDate);
	const [nextDate, setNextDate] = useState(null);
	const [balances, setBalances] = useState([]);
	const [sumPayout, setSumPayout] = useState(0);
	const [payoutPerShare, setPayoutPerShare] = useState(0.00001);
	const [showConfirm, setShowConfirm] = useState(false);

	const payoutPerShareRef = useRef();

	useEffect(() => {
		if (!lastDate)
			dispatch(doFetchLastDate());
	}, []);

	useEffect(() => {
		if (!lastDate || !parseInt(lastDate)) {
			let currentYear = new Date().getFullYear();
			let currentMonth = new Date().getMonth() + 1;
			currentMonth--;
			if (currentMonth<=0) {
				currentMonth = currentMonth + 12;
				currentYear--;
			}
			setNextDate('' + (currentYear * 100 + currentMonth))
			return;
		}
		let year = Math.floor(parseInt(lastDate) / 100);
		let month = parseInt(lastDate) % 100;
		month++;
		if (month > 12) {
			month = month - 12;
			year ++;
		}
		setNextDate('' + (year * 100 + month))
		
	}, [lastDate]);


	const onPayoutPerShareKeyUp = (e) => {
		let p = e.target.value;
		setPayoutPerShare(p);
		setBalances(balances.map(b => ({
			address: b.address, balance: b.balance, payout: b.balance * p, ignored: b.ignored
		})));
	}

	const handleCsv = (e) => {
		console.log(e);
		if (!e.target?.files || e.target.files.length <= 0)
			return;
		var fr = new FileReader();
		fr.onload = function () {
			let arr = CSVToArray(fr.result);
			let balances = [];
			for (let a of arr) {
				if (!a[0].startsWith('0x'))
					continue;
				let address = a[0];
				let balance = parseInt(a[1]);
				//console.log('---->itt', PAYOUT_IGNORE_ACCOUNTS, PAYOUT_IGNORE_ACCOUNTS.indexOf(address))
				let ignored =
					!!(isNaN(balance) || PAYOUT_IGNORE_ACCOUNTS.find(a => a.toLowerCase() == address.toLowerCase()));
				balances.push({ address: a[0], balance, payout: balance * payoutPerShare, ignored });
			}
			setBalances(balances);
		}
		fr.readAsText(e.target.files[0]);
	}

	useEffect(() => {
		let sum = 0;
		for (let b of balances) {
			if (!b.ignored)
				sum += b.payout;
		}
		setSumPayout(sum);
	});

	const payout = () => {
		setShowConfirm(false);
		dispatch(doPayout({ balances: balances.filter(b => !b.ignored), date : nextDate }));
	}

	return (
		<div className="page">


			<Modal
				show={showConfirm}
				backdrop="static"
				keyboard={false}
				centered={true}
			>
				<Modal.Header>
					Confirm
				</Modal.Header>

				<Modal.Body >
					<div>Payout <span className='fw-bold amt'>{dispNum(sumPayout)}</span>&nbsp;USDT to <span className='fw-bold amt'>{balances.length}</span> stakeholders?</div>
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary" onClick={() => setShowConfirm(false)} >Cancel</button>
					<button className="btn btn-primary" onClick={() => payout()} >Payout</button>
				</Modal.Footer>
			</Modal>





			<h1>Payout</h1>

			<div className="page-main">

				<div className="block">
					<span>
						<b>1.</b>&nbsp;Download balances csv from &nbsp;
						<a href={`${CHAIN.blockExplorerUrls}exportData?type=tokenholders&contract=${token_address}&decimal=18`}
							target="_blank" >here</a>.
					</span>
				</div>
				<div className="block">
					<div>
						<b>2.</b>&nbsp;<input type="file" onChange={handleCsv} />
					</div>
				</div>

				<div className="block">
					<div className="btn-group">
						<input type="number" className="form-control amt" style={{ textAlign: 'right' }}
							value={payoutPerShare}
							ref={payoutPerShareRef}
							onChange={onPayoutPerShareKeyUp} />
						<span>USDT / SDBN1</span>
					</div>
				</div>


				{balances?.length > 0 && (
					<div className="block">
						<div className="payout-table-container">
							<table className="list">
								<thead>
									<tr>
										<th>Helder address</th>
										<th align="right" >Balance</th>
										<th align="right" >Payout</th>
									</tr>
								</thead>
								<tbody>
									{balances.map(balance => (<tr key={balance.address} className={balance.ignored ? 'ignored' : ''} >
										<td align="left">{dispAddress(balance.address)}</td>
										<td align="right" className="amt">{dispNum(balance.balance, 0)}</td>
										<td align="right" className="amt">{dispNum(balance.payout)}</td>
									</tr>))}
								</tbody>
							</table>
						</div>

						<div className="block block-value">
							<span className="title">Total payout</span>
							<span className="value">
								<BlockAmount amount={sumPayout} />
								<span className="dim">USDT</span>
							</span>
						</div>

						<div className="block block-value">
							<span className="title">Payout date</span>
							<span className="value">
								{nextDate 
								? nextDate.substr(0, 4) + "/" + nextDate.substr(-2)
								: '?'}
							</span>
						</div>

						<div className="block block-value">
							<button className="btn btn-primary" onClick={() => setShowConfirm(true)} >Payout</button>
						</div>

					</div>)}
			</div >
		</div>
	)
}









