import './App.scss';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect, disconnect, doLogin, errorClose } from './web3Slice';
import { Modal, Button } from 'react-bootstrap';
import ToastMessages from '../toastMessages';

import { Route, Link, BrowserRouter as Router, Routes } from "react-router-dom";
import Dashboard from '../features/wallet/Wallet';
import Buy from '../features/buy/Buy';
import Referral from '../features/referral/Referral';
import OwnerStatistics from '../features/owner/ownerStatistics';
import WalletRecovery from '../features/recovery/WalletRecovery';
import Payout from '../features/payout/Payout';
import Withdraw from '../features/payout/Withdraw';
import { dispAddress } from '../common';
import EarlyPayout from '../features/earlyPayout/EarlyPayout';



export default function App() {

	// ------- selectors -----------
	const status = useSelector((state) => state.web3.status);
	const statusText = useSelector((state) => state.web3.statusText);
	const userData = useSelector(state => state.web3.userData);
	const walletAddress = useSelector((state) => state.web3.walletAddress);


	const statusModalRef = useRef(null);

	const dispatch = useDispatch();

	const showSpinner = (['query', 'networksetup'].indexOf(status) > -1);

	const disconnectIt = () => {
		if (window.confirm("Disconnect wallet?")) {
			dispatch(disconnect());
		}
	}

	const [design, setDesign] = useState(true);
	/*const [design, setDesign] = useState(!!localStorage.getItem('design'));
	const designIt = (b) => {
		localStorage.setItem('design', b);
		setDesign(b);
	}*/



	return (
		<div className={"App " + (design ? ' design' : '')}>
			<div className="page-container" >
				<Modal
					show={status == 'error'}
					centered={true}
					onHide={() => dispatch(errorClose())}
				>
					<Modal.Header closeButton>
						<Modal.Title>Error</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: statusText }}></div>
					</Modal.Body>
					<Modal.Footer>
						{status == 'connecterror' && <Button variant="btn btn-primary" onClick={() => dispatch(connect())} >Try again</Button>}
						{status != 'connecterror' && <Button variant="btn btn-primary" onClick={() => dispatch(errorClose())} >Ok</Button>}
					</Modal.Footer>
				</Modal>

				<Modal
					show={showSpinner}
					backdrop="static"
					keyboard={false}
					centered={true}
				>
					<Modal.Body className='spinner-body' >
						<br /><br />
						<img src="/images/spinner_orange.png" />
						<br /><br />
						<div dangerouslySetInnerHTML={{ __html: statusText }}></div>
						<br /><br />
					</Modal.Body>
				</Modal>

				{status == 'disconnected' && <div className="page" style={{ justifyContent: 'center' }}>
					<div className='page-main'>
						<br /><br /><br /><br />
						<img className='img-fluid' src="/images/logotext.png" style={{ width: '400px' }} />
						<br /><br /><br /><br />
						{!window.ethereum
							&& <a className='btn btn-primary' href="https://metamask.io/" target="_blank">Install metamask</a>
						}
						<br /><br /><br />
						<button className='btn btn-primary' onClick={() => dispatch(connect())}  > Connect wallet</button>
						<br /><br /><br />
					</div>
				</div>}

				{false && status != 'disconnected' && !userData?.userId && <div className="page" style={{ justifyContent: 'center' }}>
					<div className='page-main'>
						<br /><br /><br /><br />
						<img src="/images/logotext.png" style={{ width: '400px' }} />
						<br /><br />
						<div className="block block-value">
							<span className="title">Wallet address</span>
							<span className="value">{dispAddress(walletAddress)}<span className="dim"></span></span>
						</div>
						<br /><br /><br /><br />
						{<button className='btn btn-primary' onClick={() => dispatch(doLogin())}  > Login</button>}
						<br /><br /><br /><br />
						{<button className='btn btn-secondary' onClick={() => disconnectIt()}  > Disconnect wallet</button>}
						<br />
					</div>
				</div>}

				{['query', 'ready', 'error'].indexOf(status) >= 0 && (true || userData?.userId) &&
					<Router  >
						<nav>
							<ul>
								<li>
									<Link to={`/`}>Wallet</Link>
								</li>
								<li>
									<Link to={`/buy`}>Buy</Link>
								</li>
								<li>
									<Link to={`/referral`}>Referral</Link>
								</li>
								<li>
									<Link to={`/withdraw`}>Withdraw</Link>
								</li>
							</ul>
						</nav>
						<Routes>
							<Route path="/" element={<Dashboard />} />
							<Route path="/buy" element={<Buy />} />
							<Route path="/referral" element={<Referral />} />
							<Route path="/owner-statistics" element={<OwnerStatistics />} />
							<Route path="/wallet-recovery" element={<WalletRecovery />} />
							<Route path="/withdraw" element={<Withdraw />} />
							<Route path="/admin/payout" element={<Payout />} />
							<Route path="/admin/early-payout" element={<EarlyPayout />} />
						</Routes>
					</Router>}

				<ToastMessages />
				<div className='popup'> {status}&nbsp;<button onClick={() => designIt(!design)}>design</button></div>
			</div>
		</div>
	)
};


