import { useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom";
import { doRecoverySave, selectAccountData } from "../../app/web3Slice";
import { dispAddress } from "../../common";

export default function WalletRecovery() {

	const walletAddress = useSelector((state) => state.web3.walletAddress);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userData = useSelector(state => state.web3.userData);
	const [email, setEmail] = useState(userData.email || '');
	const [phone, setPhone] = useState(userData.phone || '');

	const saveIt = () => {
		dispatch(doRecoverySave({ email, phone }));
		navigate("/");

	}

	return (
		<div className='page'>

			<h1>Wallet recovery</h1>
			<div className='page-main'>
				<div className="block block-value">
					<span className="title">Wallet address</span>
					<span className="value">{dispAddress(walletAddress)}</span>
				</div>

				<div className="block block-value">
					<span className="title">Recovery email address</span>
					<div className="btn-group">
						<input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
					</div>
				</div>

				<div className="block block-value">
					<span className="title">Recovery phone number</span>
					<div className="btn-group">
						<input type="text" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} />
					</div>
				</div>


				<div className="block block-value">
					<button className="btn btn-primary"
						onClick={() => saveIt()}>Save recovery info</button>
				</div>

			</div>
		</div>
	)
}