import { useState } from "react";
import { useDispatch } from "react-redux";
import { CSVToArray, dispAddress, dispNum } from "../../common";
import { doEarlyPayoutMulti, doEarlyPayoutSingle } from "./earlyPayoutSlice";

export default function EarlyPayout() {

	const dispatch = useDispatch();
	const [address, setAddress] = useState("");
	const [amount, setAmount] = useState(0);
	const [multi, setMulti] = useState([]);

	const handleCsv = (e) => {
		console.log(e);
		if (!e.target?.files || e.target.files.length <= 0)
			return;
		var fr = new FileReader();
		fr.onload = function () {
			let arr = CSVToArray(fr.result);
			let multi = [];
			for (let a of arr) {
				if (!a[0].startsWith('0x'))
					continue;
				let address = a[0];
				let amount = parseFloat(a[1]);
				multi.push({ address, amount });
			}
			setMulti(multi);
		}
		fr.readAsText(e.target.files[0]);
	}

	return (<div className="page">
		<h1>Early payout</h1>

		<div className="page-main">

			<div className="block">
				<span className="title">Address</span>
				<div className="btn-group">
					<input type="text" className="form-control" style={{}}
						value={address} onChange={(e) => setAddress(e.target.value)} />
				</div>
			</div>

			<div className="block">
				<span className="title">Amount</span>
				<div className="btn-group">
					<input type="number" className="form-control amt" style={{ textAlign: 'right' }}
						value={amount} onChange={(e) => setAmount(parseFloat(e.target.value))} />
					<span>USDT / SDBN1</span>
				</div>
			</div>

			<div className="block">
				<button className="btn btn-primary" onClick={() => dispatch(doEarlyPayoutSingle({ address, amount }))} >Pay single</button>
			</div>

			<hr />
			<h2>Multi Sender</h2>
			<div className="block">
				<div>
					<b>2.</b>&nbsp;<input type="file" onChange={handleCsv} />
				</div>
			</div>


			{multi?.length > 0 && (
				<div className="block">
					<div className="payout-table-container">
						<table className="list">
							<thead>
								<tr>
									<th>Address</th>
									<th align="right" >Amount</th>
								</tr>
							</thead>
							<tbody>
								{multi.map(row => (<tr key={row.address} className={row.ignored ? 'ignored' : ''} >
									<td align="left">{dispAddress(row.address)}</td>
									<td align="right" className="amt">{dispNum(row.amount, 0)}</td>
								</tr>))}
							</tbody>
						</table>
					</div>

					<div className="block block-value">
						<button className="btn btn-primary" onClick={() => dispatch(doEarlyPayoutMulti(multi))} >Payout</button>
					</div>

				</div>)}


		</div>





	</div>);

}