import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dispNum } from "../../common";
import { doFetchPayouts, doWithdraw } from "./payoutSlice";


export default function Withdraw() {
	const dispatch = useDispatch();
	const payouts = useSelector(state => state.web3Payout.payouts);
	const loaded = useSelector(state => state.web3Payout.loaded);
	const status = useSelector(state => state.web3.status);

	useEffect(() => {
		if (status == 'ready' && !loaded)
			dispatch(doFetchPayouts());
	}, [status, loaded]);

	return (<div className="page">
		<h1>Withdraw</h1>

		<div className="page-main">
			<div className="block">
				{payouts.length == 0 && <div>No payout available for withdrawal...</div>}
				{payouts.length > 0 && <div className="payout-table-container">
					<table>
						<tbody>
							{payouts.map(payout => <tr key={payout.id} >
								<td className="px-2">{payout.date.substr(0, 4) + "/" + payout.date.substr(-2)}</td>
								<td className="px-2" align="right"><span className="amt">{dispNum(payout.userAmount)}</span>&nbsp;USDT</td>
								<td className="px-2" >
									{payout.status
										? <button className="btn btn-primary btn-sm" onClick={() => dispatch(doWithdraw(payout))} >Withdraw</button>
										: <span className="text-muted">Withdrawed</span>}
								</td>
							</tr>)}
						</tbody>
					</table>
				</div>}
			</div>
			<hr />
			<div className="block">
				<button className="btn btn-outline-secondary" onClick={() => dispatch(doFetchPayouts())} >Refresh payouts</button>
			</div>
		</div>
	</div>
	);
}