import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { usdt_abi } from "../../config/abi";
import { payout_abi } from "../../config/abiPayout";
import {  sun3, transactionConfirmPlease, transactionError, transactionQuery, transactionQueryError, transactionReady, transactionWaitsChain, withdrawBalanceUpdated } from "../../app/web3Slice";
import { payout_address, usdt_address } from "../../config/config";

export const web3PayoutSlice = createSlice({
	name: 'web3/payout',
	initialState: {
		payouts: [],
		loaded: false,
		lastDate: null

	},
	reducers: {
		payoutsFetched: (state, action) => {
			state.payouts = action.payload;
			state.loaded = true;
		},
		withdrawed : (state, action) => {
			const payout = action.payload;
			let p = state.payouts.find(p => p.id == payout.id);
			if (p)
				p.status = false;
		},
		lastDateSet : (state, action) => {
			state.lastDate = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(doPayout.rejected, (state, action) => { console.error('doPayout REJECTED!', action.error); });
		builder.addCase(doWithdraw.rejected, (state, action) => { console.error('doWithdraw REJECTED!', action.error); });
		builder.addCase(doFetchPayouts.rejected, (state, action) => { console.error('doFetchPayouts REJECTED!', action.error); });
		builder.addCase(doFetchLastDate.rejected, (state, action) => { console.error('doFetchLastDate REJECTED!', action.error); });
	}
});


export const { payoutsFetched, withdrawed, lastDateSet } = web3PayoutSlice.actions;
export default web3PayoutSlice.reducer;


export const doFetchLastDate = createAsyncThunk('web3/payout/doFetchLastDate', async (_, thunkAPI) => {
	try {
		transactionQuery(thunkAPI, "Fetching your new balance");
		const web3 = new Web3(sun3.provider);
		const payout = new web3.eth.Contract(payout_abi, payout_address);

		let lastDate = await payout.methods.lastDate().call();
		console.log('lastDate', lastDate, typeof lastDate)
		thunkAPI.dispatch(lastDateSet(lastDate));

		transactionReady(thunkAPI);
	} catch(ex) {
		transactionQueryError(thunkAPI, ex);
	}

});

export const doWithdraw = createAsyncThunk('web3/payout/doWithdraw', async (payoutToWithdraw, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);
		const web3 = new Web3(sun3.provider);
		const payout = new web3.eth.Contract(payout_abi, payout_address);
		const usdt = new web3.eth.Contract(usdt_abi, usdt_address);

		await payout.methods.withdraw(payoutToWithdraw.date)
			.send({ from: sun3.selectedAccount })
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					console.log('confirm', confirmationNumber, receipt);
					transactionQuery(thunkAPI, "Fetching your new balance");

					let uBalance = await usdt.methods.balanceOf(sun3.selectedAccount).call();
					uBalance = (uBalance / 1e18).toFixed(4);

					let cBalance = await usdt.methods.balanceOf(payout_address).call();
					cBalance = (cBalance / 1e18).toFixed(4);


					thunkAPI.dispatch(withdrawBalanceUpdated({uBalance, cBalance}));
					thunkAPI.dispatch(withdrawed(payoutToWithdraw));
					transactionReady(thunkAPI);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
			;
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}
});

export const doFetchPayouts = createAsyncThunk('web3/payout/doFetchPayouts', async (_, thunkAPI) => {
	try {
		thunkAPI.dispatch(payoutsFetched([]));
		transactionQuery(thunkAPI, "Fetching payouts");
		const web3 = new Web3(sun3.provider);
		const payout = new web3.eth.Contract(payout_abi, payout_address);

		let wDates = await payout.methods.userDatesList().call({ from: sun3.selectedAccount });
		console.log(wDates);

		let payouts = [];
		for (let id in wDates) {
			let date = wDates[id];
			let status = await payout.methods.getUserWithdrawed(id).call({ from: sun3.selectedAccount });
			let userAmount = await payout.methods.userAmounts(sun3.selectedAccount, id).call() / 1e18;
			console.log(id, status, userAmount, date);
			payouts.push({
				id, status, userAmount, date
			});
		}
		thunkAPI.dispatch(payoutsFetched(payouts));
		transactionReady(thunkAPI, "Payouts fetched");
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}

});

export const doPayout = createAsyncThunk('web3/payout/doPayout', async ({ balances, date }, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);
		const web3 = new Web3(sun3.provider);

		let addresses = [];
		let amounts = [];
		for (let b of balances) {
			addresses.push(b.address);
			amounts.push(BigInt(b.payout * 1e18));
		}
		let aLength = addresses.length;
		const payout = new web3.eth.Contract(payout_abi, payout_address);

		let tx = await payout.methods.multiAllower(
			addresses,
			amounts,
			date,
			aLength
		)
			.send({
				from: sun3.selectedAccount
			})
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);

			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				transactionReady(thunkAPI, 'Payout successful!');
				let lastDate = await payout.methods.lastDate().call();
				thunkAPI.dispatch(lastDateSet(lastDate));				
			})
			;
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}

});