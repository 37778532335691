
//export const API_BASE = "http://localhost:25002/api";
//export const API_BASE = "http://176.58.118.145:25001/api";
export const API_BASE = "https://sunbuy.coimbraits.hu:25001/api";
//export const API_BASE = "https://token.sdbn1.com:25001/api";


export const sale_address = "0x893946101b7B24270734554E9Ace4da4BdAf23a4";
export const usdt_address = "0x3749C4a6D6fe2A72D7381184DDFdF563e2189f23";
export const token_address = "0xF619B07da7892F2B3Cb005ECbCCbE087AB6cEaAc";
//export const payout_address = "0xb8C2BB4aceADF305bd832C894f5C5d76302CcA42";
export const payout_address = "0x4F1E1F71ef929FA19Ac7C8DB71eC4eA7437725Da"; // from SDBN2 testnet
export const PAYOUT_IGNORE_ACCOUNTS = [sale_address];



export const CHAIN = {
	chainId: '0x61',
	chainName: 'Binance Smart Chain Testnet',
	nativeCurrency: {
		name: 'tBNB',
		symbol: 'tBNB',
		decimals: 18
	},
	rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
	blockExplorerUrls: ['https://testnet.bscscan.com/']
};


/*export const CHAIN = {
	chainId: '0x38',
	chainName: 'Binance Smart Chain',
	nativeCurrency: {
		name: 'Binance Coin',
		symbol: 'BNB',
		decimals: 18
	},
	rpcUrls: ['https://bsc-dataseed.binance.org/'],
	blockExplorerUrls: ['https://bscscan.com/']
}*/
