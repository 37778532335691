import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sun3, transactionConfirmPlease, transactionError, transactionQueryError, transactionReady, transactionWaitsChain } from "../../app/web3Slice";
import { sale_abi } from "../../config/abi";
import { sale_address } from "../../config/config";

export const web3EarlyPayoutSlice = createSlice({
	name: 'web3/earlyPayout',
	initialState: {
	},
	reducers: {
	},
	extraReducers: (builder) => {
		builder.addCase(doEarlyPayoutSingle.rejected, (state, action) => { console.error('doEarlyPayoutSingle REJECTED!', action.error); });
		builder.addCase(doEarlyPayoutMulti.rejected, (state, action) => { console.error('doEarlyPayoutMulti REJECTED!', action.error); });
	}
});


export const { } = web3EarlyPayoutSlice.actions;
export default web3EarlyPayoutSlice.reducer;


export const doEarlyPayoutSingle = createAsyncThunk('web3/earlyPayout/doSingle', async ({ address, amount }, thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);

		amount = BigInt(amount * 1e18);

		const sale = new web3.eth.Contract(sale_abi, sale_address);


		let tx = await sale.methods.oneSender(
			address,
			amount
		)
			.send({
				from: sun3.selectedAccount
			})
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					console.log('confirm', confirmationNumber, receipt);
					let allSold = await sale.methods.soldAmount().call();
					transactionReady(thunkAPI, `allSold=${allSold}`);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
			;

		transactionReady(thunkAPI, 'Payout successful');
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}
});

export const doEarlyPayoutMulti = createAsyncThunk('web3/earlyPayout/doMulti', async ( multi , thunkAPI) => {
	try {
		transactionConfirmPlease(thunkAPI);

		const web3 = new Web3(sun3.provider);

		let addresses = [];
		let amounts = [];
		for (let b of multi) {
			addresses.push(b.address);
			amounts.push(BigInt(b.amount * 1e18));
		}
		let length = addresses.length;

		const sale = new web3.eth.Contract(sale_abi, sale_address);


		let tx = await sale.methods.multiSender(
			addresses,
			amounts,
			length
		)
			.send({
				from: sun3.selectedAccount
			})
			.once('transactionHash', function (hash) {
				transactionWaitsChain(thunkAPI, hash);
			})
			.once('confirmation', async function (confirmationNumber, receipt) {
				try {
					console.log('confirm', confirmationNumber, receipt);
					let allSold = await sale.methods.soldAmount().call();
					transactionReady(thunkAPI, `allSold=${allSold}`);
				} catch (ex) {
					transactionQueryError(thunkAPI, ex);
				}
			})
			;

		transactionReady(thunkAPI, 'Payout successful');
	} catch (ex) {
		transactionError(thunkAPI, ex);
	}
});