import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disconnect, selectAccountData, addToken, doLogin, doLogout, fetchAccountData } from '../../app/web3Slice';
import { BlockAmount, BlockDim, dispAddress } from '../../common';
import { Link } from 'react-router-dom';




export default function Dashboard() {

	// ------- selectors -----------
	const walletAddress = useSelector((state) => state.web3.walletAddress);
	const accountData = useSelector(selectAccountData);
	const userData = useSelector(state => state.web3.userData);


	const dispatch = useDispatch();

	const [buyAmountCalc, setBuyAmountCalc] = useState(null);
	const onBuyAmountKeyUp = (e) => {
		console.log(e.target.value);
		setBuyAmountCalc(parseInt(e.target.value) / 0.01);
	}

	const disconnectIt = () => {
		if (window.confirm("Disconnect wallet?")) {
			dispatch(disconnect());
		}
	}


	return (
		<div className="page">
			<h1>Wallet</h1>
			<div className="page-main">
			<div className="block block-value">
				<span className="title">Wallet address</span>
				<span className="value">{dispAddress(walletAddress)}<span className="dim"></span></span>
			</div>

			<div className="block block-value">
				<span className="title">Token balance</span>
				<span className="value">
					<BlockAmount amount={accountData.tBalance} />
					<BlockDim dim="SDBN1" />
				</span>
			</div>

			<div className="block block-value">
				<span className="title">USDT balance</span>
				<span className="value">
					<BlockAmount amount={accountData.uBalance} />
					<span className="dim">USDT</span>
				</span>
			</div>

			<div className="block block-value">
				<span className="title">BNB Balance</span>
				<span className="value">
					<BlockAmount amount={accountData.bBalance} />
					<span className="dim">BNB</span>
				</span>
			</div>

			<hr />

			<div className="other-buttons">
				
				{!userData?.userId 
				?
					<button className='btn btn-danger' onClick={() => dispatch(doLogin())} >Login</button>
				:	(userData?.email || userData?.phone
					? <Link className='btn btn-outline-secondary'	to={`/wallet-recovery`}   >Wallet recovery</Link>
					: <Link className='btn btn-danger'	to={`/wallet-recovery`}   >Wallet recovery!</Link>
				)}
				
				<button className='btn btn-outline-secondary' onClick={() => dispatch(addToken())}  >Add SDBN1 to your wallet</button>
			</div>

			{!!userData?.isAdmin && <div>
				<hr />
				<div className="other-buttons">
					<Link className='btn btn-outline-secondary' to={`/owner-statistics`}>Owner statistics</Link>
					<Link className='btn btn-outline-secondary' to={`/admin/payout`}>Payout</Link>
					<Link className='btn btn-outline-secondary' to={`/admin/early-payout`}>Early payout</Link>
				</div>
			</div>}

			<hr/>
			<div className="other-buttons">
				{!!userData?.userId && <button className='btn btn-outline-secondary' onClick={() => dispatch(doLogout())}  >Logout</button>}
				<button className='btn btn-secondary' onClick={() => disconnectIt()}  > Disconnect wallet</button>
			</div>
		</div>
		</div>
	)
};


